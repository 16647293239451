import qs from "qs";

const queryParams = qs.parse(location.search);

const AppParams = new Proxy({}, {
    get(obj, prop) {
        const value = prop in queryParams ?
            queryParams[prop] :
            undefined;
        if (value && value.match(/^[0-9]+$/)) {
            return parseInt(value);
        }
        return value;
    }
})

export default AppParams;